import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders a product variant
 */
class ProductCard extends HTMLElement implements CustomElement {
  static IMAGE_ASPECT_RATIO = '4:5';

  static IMAGE_RESPONSIVE_STEPS: Record<string, number[]> = {
    '320': [370, 370 * 2, 370 * 3],
    '375': [180, 180 * 2, 180 * 3],
    '425': [180, 180 * 2, 180 * 3],
    '600': [270, 270 * 2, 270 * 3],
    '768': [315, 315 * 2, 315 * 3],
    '1024': [284, 284 * 2, 284 * 3],
    '1200': [370, 370 * 2, 370 * 3]
  };

  readonly dataset!: {
    /**
     * Badge displayed on top of the product image
     */
    badgeType: Product['badge_type'];

    /**
     * Selected Shopify country code, it is used by the add to cart button element.
     */
    countryCode: string;

    /**
     * Currency ISO Code. Required.
     */
    currencyCode: string;

    /**
     * Currency Symbol. Required.
     */
    currencySymbol: string;

    /**
     * Email of currently signed in customer.
     */
    customerEmail: string;

    /**
     * Boolean represented as string to render variant options.
     */
    enableVariantOptions?: string;

    /**
     * If forcedImage provided this will be rendered instead of the product variant image.
     */
    forcedImage?: string;

    /**
     * Defines is the add to bag element rendered (visible), boolean value.
     */
    hideBuyButton: string;

    /**
     * Defines is the product price element rendered (visible), boolean value.
     */
    hidePrice: string;

    /**
     * in use by pages/collection/filters updateCollectionProductsWithFilterGroups
     *
     * @todo document me
     */
    id: string;

    /**
     * Marketing message shown after the rating element. Used in the featured products.
     */
    marketingMessage?: string;

    /**
     * Sets visual outline style for the add to bag button.
     */
    outlineBuyButton: string;

    /**
     * Sets alternative text to be rendered instead of price.
     */
    priceReplacement: string;

    /**
     * If data-select-option dataset attribute is passed in with a value, we want to replace the add
     * to cart button with a select option button that will render a variant select modal when
     * clicked.
     */
    selectOption: string;

    /**
     * in use by pages/collection/filters updateCollectionProductsWithFilterGroups
     *
     * @todo document me
     */
    tags: string;

    /**
     * The value of liquid template.name
     *
     * @todo use a feature flag instead, this is a difficult todo because this value is passed
     * through to nested elements that still require the value, so it will take some work
     */
    templateName: string;

    /**
     * in use by pages/collection/filters updateCollectionProductsWithFilterGroups
     *
     * @todo document me
     */
    url: string;
  };

  public shadowRoot!: ShadowRoot;
  private onSelectOptionClickBound = this.onSelectOptionClick.bind(this);
  private onSlotChangeBound = this.onSlotChange.bind(this);
  private onProductVariantOptionChangedBound = this.onProductVariantOptionChanged.bind(this);
  private onDOMContentLoadedBound = this.onDOMContentLoaded.bind(this);
  private product: Partial<Product> = {};

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    const slot = this.shadowRoot.querySelector<HTMLSlotElement>('slot[name="product-card-data"]');
    slot?.addEventListener('slotchange', this.onSlotChangeBound);

    // this should work only for a product card with the exposed options selector, currently only
    // in the featured products section.
    if (this.dataset.enableVariantOptions === 'true') {
      addEventListener('product-variant-option-changed', this.onProductVariantOptionChangedBound);
    }

    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      setTimeout(this.onDOMContentLoadedBound);
    } else {
      addEventListener('DOMContentLoaded', this.onDOMContentLoadedBound);
    }
  }

  public disconnectedCallback() {
    const slot = this.shadowRoot.querySelector<HTMLSlotElement>(
      'slot[name="product-card-data"]');
    slot?.removeEventListener('slotchange', this.onSlotChangeBound);

    removeEventListener('product-variant-option-changed', this.onProductVariantOptionChangedBound);
    removeEventListener('DOMContentLoaded', this.onDOMContentLoadedBound);
  }

  private onDOMContentLoaded(_event: Event) {
    this.gatherData();
    this.render();
  }

  private onSlotChange(_event: Event) {
    // this event is called when the slot is changed, but also when the element is destroyed
    // this causes race conditions in exotic browsers, i.e. neither the slot contents, nor
    // the shadowRoot contents are guaranteed to exist here

    // only gather data and render if we have the slot properly filled
    const productElement = this.querySelector('[slot="product-card-data"]');
    if (productElement) {
      this.gatherData();
      this.render();
    }
  }

  private onProductVariantOptionChanged(event: WindowEventMap['product-variant-option-changed']) {
    if (this.product.id === event.detail.id) {
      const element = this.querySelector<HTMLElement>(
        `[slot="product-card-data"] > [data-id="${event.detail.selected_variant_id}"]`);
      if (element) {
        const url = element.dataset.imageSrc;
        if (url) {
          this.renderMainImage(url);
        }
      }
    }
  }

  /**
   * Gathers state into the product property and then calls render.
   */
  private gatherData() {
    type VariantElement = HTMLElementTagNameMap['variant-element'];

    const productElement = this.querySelector<HTMLElementTagNameMap['product-element']>(
      '[slot="product-card-data"]');
    if (!productElement) {
      return;
    }

    this.product.award_badge_image = productElement.dataset.awardBadgeImage;
    this.product.award_tag = productElement.dataset.awardTag;
    this.product.available = productElement.dataset.available === 'true';
    this.product.badge_type = productElement.dataset.badgeType;
    this.product.metafields_discount_code = productElement.dataset.discountCode;
    this.product.metafields_discount_percentage = productElement.dataset.discountPercentage ?
      parseFloat(productElement.dataset.discountPercentage) :
      0;
    this.product.metafields_discount_override = productElement.dataset.discountOverride;
    this.product.id = parseInt(productElement.dataset.id, 10);
    this.product.lazyload = productElement.dataset.lazyload === 'true';
    this.product.main_image = productElement.dataset.mainImage;
    this.product.hover_image = productElement.dataset.hoverImage;
    this.product.metafields_title = productElement.dataset.metafieldsTitle;
    this.product.metafields_subtitle = productElement.dataset.metafieldsSubtitle;
    this.product.tags = productElement.dataset.tags;
    this.product.title = productElement.dataset.title;
    this.product.type = productElement.dataset.type;
    this.product.url = productElement.dataset.url;
    this.product.selected_variant_id = parseInt(productElement.dataset.selectedVariantId, 10);

    this.product.option_groups = <Product['option_groups']>[];
    type ProductOptionGroup = HTMLElementTagNameMap['product-option-group'];
    const optionGroupElements = productElement.querySelectorAll<ProductOptionGroup>(
      'product-option-groups product-option-group[data-name]');
    for (const optionGroupElement of optionGroupElements) {
      const optionGroup = <Product['option_groups'][0]>{};
      optionGroup.name = optionGroupElement.dataset.name;
      optionGroup.selected_option = optionGroupElement.dataset.selectedOption;
      if (optionGroupElement.dataset.position) {
        optionGroup.position = parseInt(optionGroupElement.dataset.position, 10);
      }

      optionGroup.options = [];

      type ProductOption = HTMLElementTagNameMap['product-option'];
      const productOptionElements = optionGroupElement.querySelectorAll<ProductOption>(
        'product-option[data-value]');

      for (const productOptionElement of productOptionElements) {
        const productOption = <Product['option_groups'][0]['options'][0]>{};
        productOption.value = productOptionElement.dataset.value;
        productOption.variants = [];

        const variantElements = productOptionElement.querySelectorAll<VariantElement>(
          'variant-element[data-id]');

        for (const variantElement of variantElements) {
          const variant = <ProductVariant>{};
          variant.available = variantElement.dataset.available === 'true';
          variant.backorder = variantElement.dataset.backorder;
          variant.compare_at_price = variantElement.dataset.compareAtPrice ?
            parseInt(variantElement.dataset.compareAtPrice, 10) : null;
          variant.id = parseInt(variantElement.dataset.id, 10);
          variant.price = parseInt(variantElement.dataset.price, 10);
          variant.selected = variantElement.dataset.selected === 'true';
          variant.sku = variantElement.dataset.sku;
          variant.title = variantElement.dataset.title || productElement.dataset.title || '';

          productOption.variants.push(variant);
        }

        optionGroup.options.push(productOption);
      }

      this.product.option_groups.push(optionGroup);
    }

    this.product.variants = [];

    // We must isolate the query selector to children of the parent <product-element> custom element
    // to ensure we exclude any <variant-element> child instances in the <product-option-groups>
    // custom element.
    const variantElements = this.querySelectorAll<VariantElement>(
      'product-element[slot="product-card-data"] > variant-element[data-id]');

    for (const variantElement of variantElements) {
      const variant = <ProductVariant>{};
      variant.available = variantElement.dataset.available === 'true';
      variant.backorder = variantElement.dataset.backorder;
      variant.compare_at_price = variantElement.dataset.compareAtPrice ?
        parseInt(variantElement.dataset.compareAtPrice, 10) : null;
      variant.hide_backorder_in_collection = variantElement.dataset.hideBackorderInCollection;
      variant.id = parseInt(variantElement.dataset.id, 10);
      variant.option1 = variantElement.dataset.option1;
      variant.option2 = variantElement.dataset.option2;
      variant.option3 = variantElement.dataset.option3;
      variant.price = parseInt(variantElement.dataset.price, 10);
      variant.selected = variantElement.dataset.selected === 'true';
      variant.sku = variantElement.dataset.sku;
      variant.title = variantElement.dataset.title || productElement.dataset.title || '';
      this.product.variants.push(variant);
    }
  }

  private render() {
    // gatherData() did not return a valid product (yet)
    if (!this.product.id) {
      return;
    }

    this.renderAwards();
    this.renderMainBadge();
    this.renderOutOfStockBadge();
    this.renderNewFormulaOverlay();
    this.renderMainImage();
    this.renderTitles();
    this.renderProductOptions();
    this.renderAddToCart();
    this.renderMarketingMessage();
    this.renderLinks();

    const addToCartButton = this.querySelector('[slot="add-to-cart-button"]');
    if (this.dataset.enableVariantOptions !== 'true' && !addToCartButton) {
      // In the case when variant options are rendered the "Add to Bag" button is passed to the slot
      this.renderSelectOptionButton();
    } else {
      this.renderViewDetailsLink();
    }
  }

  private renderAwards() {
    const awardsContainer = this.shadowRoot.querySelector('.awards');
    if (!awardsContainer) {
      return;
    }

    if (this.product.award_tag && this.product.award_badge_image) {
      const badge = awardsContainer.querySelector<HTMLImageElement>(
        '.awards-wrapper-image');
      badge.alt = this.product.award_tag;
      badge.src = this.product.award_badge_image;
      awardsContainer.classList.remove('hidden');
    }
  }

  private renderMainBadge() {
    const badge = this.shadowRoot.querySelector<HTMLElement>('.main-badge');
    if (!badge || !this.product.badge_type || !this.product.available) {
      return;
    }

    const badgeTitle = getBadgeTitle(this.product.badge_type);
    badge.dataset.badgeType = badgeTitle;

    if (this.product.badge_type === 'ideal-for-you') {
      badge.innerHTML = '<span class="icon"></span><span>Ideal for you</span>';
    } else {
      badge.textContent = badgeTitle;
    }

    badge.classList.add(`badge--${this.product.badge_type}`);
    badge.classList.remove('hidden');
  }

  private renderOutOfStockBadge() {
    const badge = this.shadowRoot.querySelector<HTMLElement>('.badge--out-of-stock');
    if (!badge) {
      return;
    }

    if (this.product.available) {
      badge.classList.add('hidden');
    } else {
      badge.classList.remove('hidden');
    }
  }

  private renderProductOptions() {
    const productOptionsEl = this.shadowRoot.querySelector<HTMLElement>('.product-options');

    if (this.product.type === 'bundle') {
      return;
    }

    if (this.product.variants.length <= 1) {
      return;
    }

    if (!productOptionsEl || !this.product.option_groups.length) {
      return;
    }

    const options: string[] = [];

    for (const optionGroup of this.product.option_groups) {
      const text = `${optionGroup.options.length} ${optionGroup.name.toLowerCase()}s`;

      if (optionGroup.options.length <= 1) {
        continue;
      }

      options.push(text);
    }

    productOptionsEl.innerText = options.join(' & ');
  }

  private renderNewFormulaOverlay() {
    // Don't show new formula overlay if the product already has a "new" batch.
    if (this.product.badge_type === 'new') {
      return;
    }

    // Not all products have tags or tags may not be a defined string, so use optional chaining

    // Add new formula overlay
    if (this.product.tags?.includes('new-formula')) {
      const container = this.shadowRoot.querySelector('.new-formula');

      // TODO: we see an error "Cannot read properties of null (reading 'classList')" that happens
      // here. in the short term we now log a message instead of throwing an error. eventually we
      // need to figure out why container would ever be null and actually fix the issue.

      if (container) {
        container.classList.remove('hidden');
      } else {
        console.log('<product-card> cannot render new formula overlay');
      }
    }
  }

  private renderMainImage(imageSrc?: string) {
    // TODO: instead of doing 3 query selectors here to get two elements, do two query selectors.
    // that is more performant.

    const newImageSrc = imageSrc || this.product.main_image;

    const picture = this.shadowRoot.querySelector<HTMLPictureElement>('.picture');

    // TODO: we continue to see the error "Cannot read properties of null (reading
    // 'querySelectorAll')" that occurs here. Exiting early with a log message is a temporary fix to
    // the problem. We eventually need to figure out why this happens and fix the issue. It is
    // happening when render is called from slot change, but it may be due to the early hacky thing
    // we did to render on connect and not just on slot change by forcing a call to onSlotChange
    // with a synthetic event (specified as null). This entire element needs to be refactored to
    // render on connect and not just on slot change. a slot change should trigger a re-render that
    // considers that a prior render may exist.

    if (!picture) {
      console.log('<product-card> element not found .picture');
      return;
    }

    const image = picture.querySelector<HTMLImageElement>('img');

    if (this.product.main_image) {
      for (const key of Object.keys(ProductCard.IMAGE_RESPONSIVE_STEPS)) {
        image.srcset = generateSrcSet(this.product.main_image,
          ProductCard.IMAGE_RESPONSIVE_STEPS[key]);
      }
    } else {
      console.warn('this.product.main_image is undefined', this.product);
    }

    // We are intentionally only setting the loading attribute for lazy loaded
    // images. This is to mitigate issues loading images in Safari when loading
    // attribute value is explicitly set to `eager`. Since the default value is
    // eager and safari can load the images properly when not set; we are not
    // setting it, if the image is not to be lazy loaded.
    if (this.product.lazyload) {
      image.loading = 'lazy';
      image.setAttribute('fetchpriority', 'low');
    }

    // if product have hover image defined we have to handle hover state
    if (!isMobileOrTablet() && this.product.hover_image) {
      image.addEventListener('mouseover', this.onImageMouseOver.bind(this));
      image.addEventListener('mouseout', this.onImageMouseOut.bind(this));
    }

    // TODO fix multiple assignment to the srcset attribute. We set a value to the srcset
    // attribute multiple times in this method so only the latest value assigned.

    if (this.dataset.forcedImage || newImageSrc) {
      image.srcset = generateSrcSet(this.dataset.forcedImage || newImageSrc,
        ProductCard.IMAGE_RESPONSIVE_STEPS['768']);
    } else {
      console.warn('cannot render forced image or new image source', this.dataset.forcedImage,
        newImageSrc);
    }

    const sizes = [
      '(min-width: 321px) and (max-width: 767px) 50vw',
      '(min-width: 768px) and (max-width: 1023px) 33.3vw',
      '(min-width: 1024px) 25vw',
      '100vw'
    ];

    image.setAttribute('sizes', sizes.join(','));
  }

  private renderTitles() {
    const title = this.shadowRoot.querySelector<HTMLElement>('.title');

    // TODO: we see a rare error `Cannot read properties of null (reading 'dataset')` pointing to
    // near this next line of code. investigate why the title element is sometimes missing. we
    // should not have to check for whether title exists here. failing to set the title is also
    // wrong. this is probably because of how this element is constructed.
    if (title) {
      title.dataset.productTitle = this.product.metafields_title || this.product.title;
      title.textContent = this.product.metafields_title || this.product.title;
    }

    const subtitle = this.shadowRoot.querySelector<HTMLElement>('.subtitle');

    // TODO: similar error as above, we sometimes see an error accessing subtitle.dataset here, for
    // some reason the subtitle element is sometimes not found. so for now we check for it to avoid
    // the error, but this is not a real bug fix, just a mitigation

    if (subtitle) {
      if (this.product.metafields_subtitle) {
        subtitle.dataset.productTitle = this.product.metafields_subtitle;
        subtitle.innerHTML = decode(this.product.metafields_subtitle);
      } else {
        subtitle.classList.add('hidden');
      }
    }
  }

  private renderAddToCart() {
    if (this.dataset.hideBuyButton === 'true') {
      return;
    }

    if (this.shouldRenderSelectOptionButton()) {
      return;
    }

    let selectedVariant = this.product.variants.find(variant =>
      variant.id === this.product.selected_variant_id);

    if (!selectedVariant) {
      selectedVariant = this.product.variants[0];
    }

    if (!selectedVariant) {
      return;
    }

    // TODO we need to review the code below. There's a slot "add-to-cart" button but here we adding
    // a button directly to the shadow root. Maybe, it should be refactored or at least documented.

    let addToCart = this.shadowRoot.querySelector('add-to-cart');

    if (addToCart) {
      // We may be re-rendering the add-to-cart element, which may already have a product-element
      // element. Reset the content of the element since we are about to append a product-element
      // again
      addToCart.innerHTML = '';
    } else {
      addToCart = document.createElement('add-to-cart');
      addToCart.dataset.templateName = this.dataset.templateName;
      addToCart.dataset.country = this.dataset.countryCode;
      addToCart.dataset.productListingDisplay = 'true';
      addToCart.dataset.backorder = selectedVariant.backorder;
      addToCart.dataset.discountCode = this.product.metafields_discount_code;
      if (this.dataset.customerEmail) {
        addToCart.dataset.customerEmail = this.dataset.customerEmail;
      }

      const container = this.shadowRoot.querySelector('.container');

      // TODO: we continue to see an error where container is not found. probably related to the
      // descendants in dom not available at a time of call issue. for now we suppress the error
      // but eventually we should investigate this issue.

      if (container) {
        container.appendChild(addToCart);
      } else {
        console.log('Element matching ".container" not found');
      }
    }

    if (this.dataset.outlineBuyButton === 'true') {
      addToCart.dataset.outlineBuyButton = 'true';
    }

    const product = document.createElement('product-element');
    product.setAttribute('slot', 'product');
    product.dataset.id = `${this.product.id}`;
    product.dataset.available = `${this.product.available}`;
    product.dataset.url = this.product.url;
    product.dataset.title = this.product.title;
    product.dataset.type = this.product.type;
    product.dataset.selectedVariantId = `${selectedVariant.id}`;
    product.dataset.tags = this.product.tags;

    for (const variant of this.product.variants) {
      const variantElement = document.createElement('variant-element');
      variantElement.dataset.id = `${variant.id}`;
      variantElement.dataset.available = `${variant.available}`;
      variantElement.dataset.sku = variant.sku;
      variantElement.dataset.title = variant.title;

      if (variant.backorder) {
        variantElement.dataset.backorder = variant.backorder;
        if (variant.hide_backorder_in_collection) {
          variantElement.dataset.hideBackorderInCollection = variant.hide_backorder_in_collection;
        }
      }

      if (this.product.tags?.includes('Contains Alcohol')) {
        const attributeEl = document.createElement('custom-attribute');
        attributeEl.dataset.key = '_contains_alcohol';
        attributeEl.dataset.value = 'true';
        variantElement.appendChild(attributeEl);
      }

      if (this.product.tags?.includes('compatibility-soleil')) {
        const attributeEl = document.createElement('custom-attribute');
        attributeEl.dataset.key = '_compatibility';
        attributeEl.dataset.value = 'soleil';
        variantElement.appendChild(attributeEl);
      }

      if (this.product.tags?.includes('compatibility-max-high-volume')) {
        const attributeEl = document.createElement('custom-attribute');
        attributeEl.dataset.key = '_compatibility';
        attributeEl.dataset.value = 'max-high-volume';
        variantElement.appendChild(attributeEl);
      }

      if (this.product.tags?.includes('final-sale')) {
        const attributeEl = document.createElement('custom-attribute');
        attributeEl.dataset.key = '_final_sale';
        attributeEl.dataset.value = 'true';
        variantElement.appendChild(attributeEl);
      }

      product.appendChild(variantElement);
    }

    addToCart.appendChild(product);
    addToCart.dataset.full = 'true';
    addToCart.dataset.update = `${Date.now()}`;
  }

  private renderMarketingMessage() {
    const element = this.shadowRoot.querySelector<HTMLElement>('.marketing-message');
    if (element) {
      if (this.dataset.marketingMessage) {
        element.textContent = this.dataset.marketingMessage;
      } else {
        element.remove();
      }
    }
  }

  private renderSelectOptionButton() {
    if (!this.shouldRenderSelectOptionButton()) {
      return;
    }

    const selectOptionButton = this.shadowRoot.querySelector<HTMLElement>('.primary-button');

    if (selectOptionButton) {
      // button already exists, does not need to be re-rendered
      return;
    }

    const button = document.createElement('button');
    button.classList.add('primary-button');
    button.innerText = 'Select Option';
    button.setAttribute('aria-label', ['Select option for', this.product.title].join(' '));

    button.classList.add('full');

    if (this.dataset.outlineBuyButton === 'true') {
      button.classList.add('outline');
    }

    button.addEventListener('click', this.onSelectOptionClickBound);

    // TODO: investigate why the element is sometimes not found, it should always be found

    const container = this.shadowRoot.querySelector('.container');
    if (container) {
      container.appendChild(button);
    } else {
      console.log('could not find container to append button');
    }
  }

  private renderViewDetailsLink() {
    const viewDetailsLink = this.shadowRoot.querySelector('.view-details-link');
    if (viewDetailsLink) {
      // button already exists, does not need to be re-rendered
      return;
    }

    const newViewDetailsLink = document.createElement('a');
    newViewDetailsLink.setAttribute('href', this.getProductUrl());
    newViewDetailsLink.innerText = 'View Details';
    newViewDetailsLink.classList.add('view-details-link');

    const container = this.shadowRoot.querySelector('.container');
    container.appendChild(newViewDetailsLink);
  }

  private renderLinks() {
    const productUrl = this.getProductUrl();

    const links = this.shadowRoot.querySelectorAll<HTMLAnchorElement>('.link');
    for (const link of links) {
      link.href = productUrl;
    }

    const clickableArea = this.shadowRoot.querySelector('clickable-area');
    if (clickableArea) {
      clickableArea.dataset.url = productUrl;
    }
  }

  private shouldRenderSelectOptionButton() {
    // Although selectOption is true we still want to render "Add to cart" button in the case when
    // the product is out of stock to show "Notify me" button. The "Notify me" button is a part of
    // the "Add to cart" button implementation.

    const selectOption = this.dataset.selectOption === 'true';
    const isOutOfStock = this.product.variants.every(variant => !variant.available);

    return this.product.variants.length > 1 && selectOption && !isOutOfStock;
  }

  private onSelectOptionClick(_event: MouseEvent) {
    type Detail = WindowEventMap['select-option-request']['detail'];
    const requestEvent = new CustomEvent<Detail>('select-option-request', {
      detail: {
        country: this.dataset.countryCode,
        product: this.product
      }
    });

    dispatchEvent(requestEvent);
  }

  private getProductUrl() {
    // We must use the URL object as Shopify sometimes appends query parameters to the product.url
    // property, such as when presenting search results.
    const productUrl = new URL(this.product.url, location.origin);
    if (this.product.variants.length > 1 && this.product.selected_variant_id) {
      productUrl.searchParams.set('variant', this.product.selected_variant_id.toString());
    }

    // Marketing can use offer override templates and metaobjects to create unique pricing and
    // discount codes. We must check if the view query string parameter exists and contains the word
    // "offer" and if so also append the value to product URL.
    const url = new URL(location.href, 'https://langehair.com');
    const viewParam = url.searchParams.get('view');
    if (viewParam?.includes('offer')) {
      productUrl.searchParams.set('view', viewParam);
    }

    return productUrl.toString();
  }

  private onImageMouseOver(event: MouseEvent) {
    const image = <HTMLImageElement>event.target;
    image.srcset = generateSrcSet(this.product.hover_image,
      ProductCard.IMAGE_RESPONSIVE_STEPS['768']);
  }

  private onImageMouseOut(event: MouseEvent) {
    const image = <HTMLImageElement>event.target;
    image.srcset = generateSrcSet(this.product.main_image,
      ProductCard.IMAGE_RESPONSIVE_STEPS['768']);
  }
}

/**
 * Create a responsive srcset value for images
 */
function generateSrcSet(url: string, responsiveSteps: number[]) {
  if (!url) {
    return '';
  }

  const output = [];
  for (let i = 0; i < responsiveSteps.length; i++) {
    if (url.includes('height=') && url.includes('width=')) {
      output.push(url
        .replace(/height=\d+/, `height=${calculateImageHeight(responsiveSteps[i])}`)
        .replace(/width=\d+/, `width=${responsiveSteps[i]}`)
        .concat(` ${responsiveSteps[i]}w`)
      );
    } else {
      output.push(
        `${url}&width=${responsiveSteps[i]}&height=` +
        `${calculateImageHeight(responsiveSteps[i])} ${i + 1}x`
      );
    }
  }

  return output.join(',');
}

function calculateImageHeight(imageWidth: number) {
  const [width, height] = ProductCard.IMAGE_ASPECT_RATIO.split(':');
  return Math.round(imageWidth / parseInt(width, 10) * parseInt(height, 10));
}

function getBadgeTitle(badgeType: string) {
  let badgeTitle = '';
  switch (badgeType) {
    case 'best-seller':
      badgeTitle = 'Best Seller';
      break;
    case 'new':
      badgeTitle = 'New';
      break;
    case 'favorite':
      badgeTitle = 'Staff\'s Favorite';
      break;
    case 'travel-friendly':
      badgeTitle = 'Travel Friendly';
      break;
    case 'ideal-for-you':
      badgeTitle = 'Ideal for you';
      break;
  }

  return badgeTitle;
}

/**
 * Quickly do a partial decoding of html entities in a string.
 *
 * To encode is to replace characters with a code. To decode is to replace the code with the
 * character.
 *
 * This is written to tolerate non-string inputs. Any non-string input such as undefined or null or
 * any other value is returned as is.
 *
 * This is not a complete decoding. This only decodes some of the codes we actually witness in the
 * data.
 */
function decode(value: string) {
  if (typeof value === 'string') {
    return value.replace(/&amp;/g, '&').replace(/&lt;/, '<').replace(/&gt;/, '>');
  }

  return value;
}

function isMobileOrTablet() {
  // opera is a non-standard so we check for it a different way

  // eslint-disable-next-line @typescript-eslint/no-deprecated
  const deviceInfo = navigator.userAgent || navigator.vendor ||
    'opera' in window ? 'true' : 'false';

  // cspell:disable
  const expression1 = '(android|bb\d+|meego).+mobile' +
    '|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris' +
    '|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront' +
    '|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian' +
    '|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk';
  // cpsell:enable

  const re1 = new RegExp(expression1, 'i');

  if (re1.test(deviceInfo)) {
    return true;
  }

  // cspell:disable
  const expression2 = '1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)' +
    '|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )' +
    '|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-' +
    '|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o' +
    '|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u' +
    '|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)' +
    '|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)' +
    '|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)' +
    '|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga' +
    '|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do' +
    '|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)' +
    '|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)' +
    '|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a' +
    '|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny' +
    '|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45' +
    '|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)' +
    '|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1' +
    '|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80' +
    '|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-';
  // cspell:enable

  const re2 = new RegExp(expression2, 'i');

  // TODO: substr is deprecated, use slice

  // eslint-disable-next-line @typescript-eslint/no-deprecated
  if (re2.test(deviceInfo.substr(0, 4))) {
    return true;
  }

  return false;
}

/**
 * Represents a Shopify product variant
 */
interface ProductVariant {
  /**
   * Whether the variant is in stock
   */
  available: boolean;

  /**
   * Whether the variant is on backorder
   */
  backorder: string;

  /**
   * The MSRP, or original sales price before discounts, per unit
   */
  compare_at_price: number;

  /**
   * The currency code associated with the price
   */
  currency_code: string;

  /**
   * @todo clarify what this means
   */
  hide_backorder_in_collection: string;

  /**
   * Variant id
   *
   * @example 12345568943
   */
  id: number;

  /**
   * The value of the variant for the first product option. If there's no first product option, then
   * nil is returned.
   */
  option1: string;

  /**
   * The value of the variant for the second product option. If there's no first product option,
   * then nil is returned.
   */
  option2: string;

  /**
   * The value of the variant for the third product option. If there's no first product option, then
   * nil is returned.
   */
  option3: string;

  /**
   * The price of the variant
   *
   * @todo clarify, is this in cents?
   */
  price: number;

  /**
   * Whether this variant is the currently selected variant out of all the variants for the product
   */
  selected: boolean;

  /**
   * The Stock Keeping Unit associated with the variant. This is another kind of product id.
   */
  sku: string;

  /**
   * The title of the variant.
   */
  title: string;
}

interface Product {
  /**
   * Product inventory availability
   */
  available: boolean;

  /**
   * Product award image
   */
  award_badge_image: string;

  /**
   * Product award tag
   */
  award_tag: string;

  /**
   * Product badge type
   */
  badge_type: '' | 'best-seller' | 'favorite' | 'ideal-for-you' | 'new' | 'travel-friendly';

  /**
   * Product main image
   */
  hover_image: string;

  /**
   * Shopify product id
   */
  id: number;

  /**
   * Product main image loading method
   */
  lazyload: boolean;

  /**
   * Product main image
   */
  main_image: string;

  /**
   * Product metafields discount code (used to promote public discount codes)
   */
  metafields_discount_code: string;

  /**
   * Product metafields discount override to use one-off messages instead of generated text
   */
  metafields_discount_override: string;

  /**
   * Product metafields discount percentage (used to render public discount code prices)
   */
  metafields_discount_percentage: number;

  /**
   * Product metafields subtitle
   */
  metafields_subtitle: string;

  /**
   * Product metafields title
   */
  metafields_title: string;

  option_groups: {
    name: string;
    options: {
      value: string;
      variants: Partial<{
        /**
         * Whether the variant has inventory in stock and is available for sale.
         */
        available: boolean;

        backorder_message: string;

        compare_at_price: number;

        customAttributes: {
          /**
           * Key or name of the attribute.
           */
          key: string;

          /**
           * Value of the attribute.
           */
          value: string;
        }[];

        hideBackorderInCollection: string;

        /**
         * Variant id
         */
        id: number;

        price: number;

        sku: string;

        title: string;
      }>[];
    }[];
    position?: number;
    selected_option: string;
  }[];

  /**
   * Product yotpo reviews average value
   */
  reviews_average: number;

  /**
   * Product yotpo reviews count
   */
  reviews_count: number;

  /**
   * Selected variant id
   */
  selected_variant_id: number;

  /**
   * Product sku
   */
  sku: string;

  /**
   * Product tags
   */
  tags: string;

  /**
   * Product title
   */
  title: string;

  /**
   * Product type
   *
   * @example "hair-care"
   */
  type: string;

  /**
   * Shopify product url
   */
  url: string;

  /**
   * Represents a product with multiple variants that is part of a value set. This value is used to
   * inform any subscribers consuming the product-variant-option-changed event that a product with
   * an ID not matching the parent product still requires it's DOM data indicating the actively
   * selected product.
   *
   * @todo this should be removed
   *
   * @deprecated
   */
  value_set_parent_id: number;

  /**
   * A list of the product's variants with some information about variant
   */
  variants: {
    /**
     * Whether the variant is in stock
     */
    available: boolean;

    /**
     * Whether the variant is on backorder
     */
    backorder: string;

    /**
     * The MSRP, or original sales price before discounts, per unit
     */
    compare_at_price: number;

    /**
     * The currency code associated with the price
     */
    currency_code: string;

    /**
     * @todo clarify what this means
     */
    hide_backorder_in_collection: string;

    /**
     * Variant id
     *
     * @example 12345568943
     */
    id: number;

    /**
     * The value of the variant for the first product option. If there's no first product option,
     * then nil is returned.
     */
    option1: string;

    /**
     * The value of the variant for the second product option. If there's no first product option,
     * then nil is returned.
     */
    option2: string;

    /**
     * The value of the variant for the third product option. If there's no first product option,
     * then nil is returned.
     */
    option3: string;

    /**
     * The price of the variant
     *
     * @todo clarify, is this in cents?
     */
    price: number;

    /**
     * Whether this variant is the currently selected variant out of all the variants for the
     * product
     */
    selected: boolean;

    /**
     * The Stock Keeping Unit associated with the variant. This is another kind of product id.
     */
    sku: string;

    /**
     * The title of the variant.
     */
    title: string;
  }[];
}

declare global {
  interface HTMLElementTagNameMap {
    'product-card': ProductCard;
  }
}

if (!customElements.get('product-card')) {
  customElements.define('product-card', ProductCard);
}
